import React, { useEffect, useState } from 'react';
import { csrfFetch } from "../../../store/csrf";
import AddContact from "../Contacts/AddContact";
import AddContactList from "./AddContactList";
import AddToContactList from "./AddToContactList";

const ContactList = ({ setShowModal= {setShowModal}, setModalType= {setModalType}, contacts = {contacts}, setContacts = {setContacts} }) => {
    const [contactLists, setContactLists] = useState(null);

    useEffect(() => {
        const fetchContacts = async() => {
            const response = await csrfFetch('/api/emails/fetch-contact-lists', {
                method: "GET"
            });
            const data = await response.json();
            setContactLists(data["contactLists"]);
        };

        if (!contactLists) {
            fetchContacts();
        }
    })

    const addNewContactList = (e) => {
        e.preventDefault();
        setModalType(<AddContactList setShowModal={setShowModal} setModalType={setModalType} contacts={contacts} setContacts={setContacts} />)
        setShowModal(true);
    }

    const addToContactList = (e, ta_contact_list_id) => {
        e.preventDefault();
        console.log(ta_contact_list_id)
        setModalType(<AddToContactList setShowModal={setShowModal} setModalType={setModalType} contacts={contacts} setContacts={setContacts} ta_contact_list_id={ta_contact_list_id}/>)
        setShowModal(true);
    }

    const showDeleteContactListSwal = (e, ta_contact_list_id) => {
        e.preventDefault();
        setShowModal(true);
        setModalType(<div className="w-[460px] h-[320px] flex flex-col items-center">
            <h2 className="font-owners-text text-4xl mt-8">Delete Contact List?</h2>
            <p>This won't delete scheduled emails.</p>
            <div className="flex mt-44 w-full justify-between px-8">
                <button className="bg-[#1bcae1] group relative font-owners-text tracking-wide flex w-[37%] justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2" onClick={() => deleteContactList(ta_contact_list_id)}>Delete</button>
                <button className="bg-zinc-300 group relative font-owners-text tracking-wide flex w-[37%] justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2" onClick={() => setShowModal(false)}>Close</button>
            </div>
        </div>)
    }

    const deleteContactList = async(ta_contact_list_id) => {
        try {
            const response = await csrfFetch('/api/emails/remove-contact-list', {
                method: 'POST',
                body: JSON.stringify({
                    ta_contact_list_id
                })
            });

            const data = await response.json();
            setTimeout(() => {
                setShowModal(false);
            }, 1200)

        } catch(error) {
            console.log(error);
        }
    }

    return(
        <div className="w-full">
            <div className="flex justify-between px-8 pt-4 font-owners-text mt-2">
                <p className="font-bold">Contact Lists</p>
                <button onClick={(e) => addNewContactList(e)} className="bg-[#1bcae1] group relative font-owners-text tracking-wide flex w-auto justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 mb-2">Add List</button>
            </div>
            <div className="mt-8 flex flex-col items-center justify-center">
                <table className="font-owners-text w-[90%] ">
                    <thead className="border-b-[.5px] border-gray-400 font-rift-soft">
                      <th className="w-[25%] px-8">id</th>
                      <th className="w-[25%] px-8">Name</th>
                      <th className="w-[25%] px-8">Contacts</th>
                      <th className="w-[25%] px-8">Created</th>
                    </thead>
                    <tbody>
                        {contactLists?.map((list, index) => (
                            <tr key={index}>
                                <td>{list.ta_contact_list_id}</td>
                                <td>{list.contact_list_name}</td>
                                <td>{JSON.parse(list.ta_contact_ids).length}</td>
                                <td>{list.createdAt.slice(0,10)}</td>
                                <button onClick={(e) => addToContactList(e, list.ta_contact_list_id)} className="bg-[#1bcae1] group relative font-owners-text tracking-wide flex w-auto justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 mb-2">Add Contacts</button>
                                <button onClick={(e) => showDeleteContactListSwal(e, list.ta_contact_list_id)} className="text-sm text-center cursor-pointer hover:text-red-600">Delete</button>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ContactList;
