import React, { useState } from 'react';
import SupportModal from '../SupportModal'

const Footer = ({ showSupportModal, setShowSupportModal}) => {

  const displaySupportModal = async(e) => {
    e.preventDefault();
    setShowSupportModal(true)
  };

  return (
    <footer className="bg-slate-200 mt-[200px] col-start-1 col-span-12 h-32">
     <div className="flex flex-col text-indigo-600">
        <div className="max-w-6xl md:mx-auto px-4 py-5 md:py-6 flex justify-between items-center">
          <div className="flex items-center">
            <a href="/about-us" className="hover:text-gray-900 mx-1 md:mx-2">About</a>
            <a href="https://www.teachersaide.io/posts/teacher-saves-time-with-ai" className="hover:text-gray-900 mx-1 md:mx-2">Blog</a>
            <a href="https://www.teachersaide.io/policies" className="hover:text-gray-900 mx-1 md:mx-2">Privacy Policy</a>
            <a href="/press" className="hover:text-gray-900 md:mx-2" onClick={displaySupportModal}>Support</a>
            <a href="mailto:teacher@teachersaide.io" className="hover:text-gray-900 mx-1 md:mx-2">Contact Us</a>
          </div>
          <div className="flex items-center">
            <a href="https://twitter.com/AIteacheraide" className="mx-1 md:mx-2"><i className="text-indigo-600 fab fa-twitter hover:text-gray-900"></i></a>
          </div>
        </div>
        <div className="text-center text-indigo-600 text-sm py-4">
          © 2024 SwiftEdge Labs LLC, All rights reserved.
        </div>
     </div>
    </footer>
  );
};

export default Footer;
