import React, { useState, useEffect } from 'react';
import { csrfFetch } from "../../../../store/csrf";
import DatePicker from "react-datepicker";

const AddCampaign = ({ setShowModal= {setShowModal}}) => {
    const [campaignName, setCampaignName] = useState(null);
    const [contactLists, setContactLists] = useState(null);
    const [templates, setTemplates] = useState(null);
    const [selectedContactList, setSelectedContactList] = useState(null);
    const [selectCount, setSelectCount] = useState(1);
    const [template1, setTemplate1] = useState(null);
    const [daysOffset1, setDaysOffset1] = useState(null);
    const [template2, setTemplate2] = useState(null);
    const [daysOffset2, setDaysOffset2] = useState(null);
    const [template3, setTemplate3] = useState(null);
    const [daysOffset3, setDaysOffset3] = useState(null);
    const [template4, setTemplate4] = useState(null);
    const [daysOffset4, setDaysOffset4] = useState(null);
    const [template5, setTemplate5] = useState(null);
    const [daysOffset5, setDaysOffset5] = useState(null);
    const [template6, setTemplate6] = useState(null);
    const [daysOffset6, setDaysOffset6] = useState(null);
    const [startDate, setStartDate] = useState(new Date());


    const fetchContactLists = async() => {
        const response = await csrfFetch('/api/emails/fetch-contact-lists', {
            method: 'GET'
        });
        const data = await response.json();
        if (data) {
            setContactLists(data.contactLists);
        }
    };

    const fetchTemplates = async() => {
        const response = await csrfFetch('/api/emails/fetch-email-templates', {
            method: 'GET'
        });
        const data = await response.json();
        if (data) {
            setTemplates(data.emailTemplates);
        }
    };

    useEffect(() => {
        if (!contactLists) {
            fetchContactLists();
        }

        if (!templates) {
            fetchTemplates();
        }
    }, []);

    const addToSelectCount = () => {
        let count = selectCount + 1;
        setSelectCount(count);
    }

    const submitCampaign = async(e) => {
        e.preventDefault();
        let templateArr = [[template1, daysOffset1], [template2, daysOffset2], [template3, daysOffset3], [template4, daysOffset4], [template5, daysOffset5], [template6, daysOffset6]];
        let templateAndDaysArr = [];

        templateArr.forEach(arr => {
            if (arr[0]) {
                templateAndDaysArr.push({
                    templateId: arr[0],
                    daysOffset: arr[1]
                })
            }
        })
        const response = await csrfFetch('/api/emails/create-email-campaign', {
            method: "POST",
            body: JSON.stringify({
                selectedContactList,
                templateAndDaysArr,
                campaignName,
                startDate
            })
        });
        const data = await response.json();

        if (data) {
            setTimeout(() => {
                setShowModal(false);
            })
            return data;
        }
    }

    return (
        <div className="w-[640px] font-owners-text">
            <div className="w-full flex flex-col justify-center items-center">
                <p className="pt-4 mb-4 text-2xl pt-6">Create Campaign</p>
                <div className="py-4 flex flex-col w-[440px]">
                    <p>Campaign Name</p>
                    <input
                        className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                        value={campaignName}
                        type="text"
                        required
                        onChange={(e) => setCampaignName(e.target.value)}
                    />
                    <select className="bg-slate-100 mt-4 h-8 font-owners-text" onChange={e => {
                        setSelectedContactList(e.target.value);
                    }}>
                        <option value="" className="font-owners-text">Select A Contact List</option>
                        {contactLists?.map((contactList, index) => (
                            <option value={JSON.stringify(contactList)}>{contactList.contact_list_name}</option>
                        ))}
                    </select>
                </div>

                <div className="flex flex-col py-6">
                    <p className="font-xl">Campaign Start Date</p>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                </div>

                {selectCount >= 0 &&
                    <div className="flex my-4">
                        <div className="flex flex-col px-2">
                            <p>Select A Template</p>
                            <select className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                            onChange={(e) => setTemplate1(e.target.value)}
                            >
                                <option value={null}>Select A Template</option>
                                {templates?.map((template, index) => (
                                    <option value={template.ta_email_template_id}>{template.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-col px-2">
                            <p>Days until next email</p>
                            <input
                                className="bg-slate-100 h-10"
                                value={daysOffset1}
                                type="number"
                                required
                                min={1}
                                onChange={(e) => setDaysOffset1(e.target.value)}
                            />
                        </div>
                    </div>
                }
                {selectCount >= 2 &&
                    <div className="flex my-4">
                        <div className="flex flex-col px-2">
                            <p>Select A Template</p>
                            <select className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                                    onChange={(e) => setTemplate2(e.target.value)}
                            >
                                <option value={null}>Select A Template</option>
                                {templates?.map((template, index) => (
                                    <option value={template.ta_email_template_id}>{template.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-col px-2">
                            <p>Days until next email</p>
                            <input
                                className="bg-slate-100 h-10"
                                value={daysOffset2}
                                type="number"
                                required
                                min={1}
                                onChange={(e) => setDaysOffset2(e.target.value)}
                            />
                        </div>
                    </div>
                }
                {selectCount >= 3 &&
                    <div className="flex my-4">
                        <div className="flex flex-col px-2">
                            <p>Select A Template</p>
                            <select className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                                    onChange={(e) => setTemplate3(e.target.value)}
                            >
                                {templates?.map((template, index) => (
                                    <option value={template.ta_email_template_id}>{template.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-col px-2">
                            <p>Days until next email</p>
                            <input
                                className="bg-slate-100 h-10"
                                value={daysOffset3}
                                type="number"
                                required
                                min={1}
                                onChange={(e) => setDaysOffset3(e.target.value)}
                            />
                        </div>
                    </div>
                }
                {selectCount >= 4 &&
                    <div className="flex my-4">
                        <div className="flex flex-col px-2">
                            <p>Select A Template</p>
                            <select className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                                    onChange={(e) => setTemplate4(e.target.value)}
                            >
                                {templates?.map((template, index) => (
                                    <option value={template.ta_email_template_id}>{template.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-col px-2">
                            <p>Days until next email</p>
                            <input
                                className="bg-slate-100 h-10"
                                value={daysOffset4}
                                type="number"
                                required
                                min={1}
                                onChange={(e) => setDaysOffset4(e.target.value)}
                            />
                        </div>
                    </div>
                }
                {selectCount >= 5 &&
                    <div className="flex my-4">
                        <div className="flex flex-col px-2">
                            <p>Select A Template</p>
                            <select className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                                    onChange={(e) => setTemplate5(e.target.value)}
                            >
                                {templates?.map((template, index) => (
                                    <option value={template.ta_email_template_id}>{template.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-col px-2">
                            <p>Days until next email</p>
                            <input
                                className="bg-slate-100 h-10"
                                value={daysOffset5}
                                type="number"
                                required
                                min={1}
                                onChange={(e) => setDaysOffset5(e.target.value)}
                            />
                        </div>
                    </div>
                }
                {selectCount > 5 &&
                    <div className="flex my-4">
                        <div className="flex flex-col px-2">
                            <p>Select A Template</p>
                            <select className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                                    onChange={(e) => setTemplate6(e.target.value)}
                            >
                                {templates?.map((template, index) => (
                                    <option value={template.ta_email_template_id}>{template.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-col px-2">
                            <p>Days until next email</p>
                            <input
                                className="bg-slate-100 h-10"
                                value={daysOffset6}
                                type="number"
                                required
                                min={1}
                                onChange={(e) => setDaysOffset6(e.target.value)}
                            />
                        </div>
                    </div>
                }
                {selectCount < 6  && <button className="hover:text-[#1bcae1] mt-2 text-xl" onClick={addToSelectCount}>Add another template</button>}
            </div>
            <div className="flex w-full justify-between px-12 py-8 mb-12">
                <button className="w-[200px] bg-[#1bcae1] group relative font-owners-text tracking-wide flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2" onClick={e => submitCampaign(e)}>Start Campaign</button>
                <button className="w-[200px] bg-zinc-300 group relative font-owners-text tracking-wide flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2" onClick={() => setShowModal(false)}>Cancel</button>
            </div>
        </div>
    )
}

export default AddCampaign;
