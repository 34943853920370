import React, { useEffect, useState } from 'react';
import { csrfFetch } from "../../../store/csrf";
import AddEmailTemplate from "./AddEmailTemplate";
import EditEmailTemplate from "./EditEmailTemplate";

const EmailTemplates = ({ setShowModal= {setShowModal}, setModalType= {setModalType}, contacts = {contacts}, setContacts = {setContacts} }) => {
    const [templates, setTemplates] = useState(null);

    useEffect(() => {
        const fetchTemplates = async() => {
            try {
                const response = await csrfFetch('/api/emails/fetch-email-templates');
                const data = await response.json();
                setTemplates(data["emailTemplates"]);
            } catch(error) {
                console.log(error);
            }
        };
        if (!templates) {
            fetchTemplates();
        }
    })

    const addEmailTemplate = (e) => {
        e.preventDefault();
        setShowModal(true)
        setModalType(<AddEmailTemplate setShowModal={setShowModal} setModalType={setModalType} />)
    }

    const editEmailTemplate = (e, ta_email_template_id, name, subject, content, emailText) => {
        e.preventDefault();
        setShowModal(true)
        setModalType(<EditEmailTemplate setShowModal={setShowModal} setModalType={setModalType} ta_email_template_id={ta_email_template_id} templateName={name} templateSubject={subject} emailContent={content} emailText={emailText} />)
    }

    const showDeleteTemplateSwal = (e, ta_email_template_id) => {
        e.preventDefault();
        setShowModal(true);
        setModalType(<div className="w-[460px] h-[320px] flex flex-col items-center">
            <h2 className="font-owners-text text-4xl mt-8">Delete Template?</h2>
            <div className="flex mt-44 w-full justify-between px-8">
                <button className="bg-[#1bcae1] group relative font-owners-text tracking-wide flex w-[37%] justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2" onClick={() => deleteTemplate(ta_email_template_id)}>Delete</button>
                <button className="bg-zinc-300 group relative font-owners-text tracking-wide flex w-[37%] justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2" onClick={() => setShowModal(false)}>Close</button>
            </div>
        </div>)
    }

    const deleteTemplate = async(ta_email_template_id) => {
        try {
            const response = await csrfFetch('/api/emails/archive-email-template', {
                method: 'POST',
                body: JSON.stringify({
                    ta_email_template_id
                })
            });

            const data = await response.json();
            setTimeout(() => {
                setShowModal(false);
            }, 1200)
            console.log(data)
        } catch(error) {
            console.log(error);
        }
    }

    return(
        <div className="w-full">
            <div className="flex justify-between px-8 pt-4 font-owners-text mt-2">
                <p className="font-bold">Email Templates</p>
                <button onClick={(e) => addEmailTemplate(e)} className="bg-[#1bcae1] group relative font-owners-text tracking-wide flex w-auto justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 mb-2">Add Template</button>
            </div>
            <div className="mt-8 flex flex-col items-center justify-center">
                <table className="font-owners-text w-[90%] ">
                    <thead className="border-b-[.5px] border-gray-400 font-rift-soft">
                    <th className="w-[25%] px-8">id</th>
                    <th className="w-[25%] px-8">Name</th>
                    <th className="w-[25%] px-8">Subject</th>
                    <th className="w-[25%] px-2">Created</th>
                    </thead>
                    <tbody>
                    {templates?.map((template, index) => (
                        <tr key={index} className="text-center text-sm">
                            <td>{template.ta_email_template_id}</td>
                            <td>{template.name}</td>
                            <td>{template.subject}</td>
                            <td>{template.createdAt.slice(0,10)}</td>
                            <button className="text-[#1ecbe1] hover:text-[#d34637]" onClick={e => editEmailTemplate(e, template.ta_email_template_id, template.name, template.subject, template.content, template.email_text)}>Edit Template</button>
                            <button onClick={e => showDeleteTemplateSwal(e, template.ta_email_template_id)}>Delete</button>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default EmailTemplates;
