import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import RichTextEditor from "../../RichTextEditor";
import TeksTrixEditor from "../TeksTrixEditor";
import PrintButton from "../../PrintButton";

const GeneratedResource = ({ setShowGeneratedContent,
                               showGeneratedContent,
                               subject,
                               language,
                               questionTypes,
                               numberOfQuestions,
                               worksheetTypes,
                               selectedChemicals,
                               selectedEquipment,
                               grade,
                               type,
                               userId,
                               selectedTek,
                           }) => {
    const [generateResponse, setGenerateResponse] = useState(true);
    const [uuid, setUuid] = useState(uuidv4())
    const [rws, setRws] = useState(null);
    const wsUrl = 'wss://www.teachersaide.io/';//'ws://localhost:8000/';////;
    const [response, setResponse] = useState("");
    const [showRichTextEditor, setShowRichTextEditor] = useState(false);

    useEffect(() => {
        if (generateResponse) {
            initiateChat()
            setGenerateResponse(false);
        }
    }, [generateResponse])
    const handleMessageEvent = (event) => {
        setResponse(prevResponse => prevResponse + event.data.replace("**", ""));

        //if (event.data === "You're out of weekly attempts.") {}
        if (rws) {
            rws.close();
            setRws(null);
        }
    };

    const initiateChat = async() => {
        if (!rws) {
            const socket = new WebSocket(wsUrl);
            //const type = 'tek-worksheet';
            socket.addEventListener('open', () => {
                const teks = [selectedTek]; //could change to pass in multiple teks
                const testQuestionTypes = questionTypes;

                socket.send(JSON.stringify({ grade, subject, userId, uuid, type, teks, language, testQuestionTypes, numberOfQuestions, worksheetTypes, selectedEquipment, selectedChemicals }));
            });

            socket.addEventListener('message', handleMessageEvent);

            setRws(socket);
            //let url = `https://www.teachersaide.io/submitted-prompts/${uuid}`

        } else {
            //rws.send(JSON.stringify({ grade, selectedTeks, userId, uuid }));
        }
    };

    return (
        <div className="md:col-start-3 md:col-end-11 h-[800px] mt-4">
            <div className="w-[100%] flex justify-between md:grid md:grid-cols-6">
                <p className="cursor-pointer col-start-1 px-8 md:px-0" onClick={() => window.location.reload()}>Back</p>
                <div className="flex md:col-start-5 md:col-span-2 pl-4">
                    <button className="h-8 w-24 rounded bg-indigo-600 hover:bg-indigo-300 text-white font-ivyora-display cursor-pointer mx-4" onClick={() => setShowRichTextEditor(true)}>Edit</button>
                    <PrintButton content={response} />
                </div>
            </div>
            <div className="w-screen md:w-[700px] whitespace-pre-wrap px-10 pt-16 pb-24 md:ml-32 md:pl-[20px] md:px-0 mb-10">
                {!showRichTextEditor && response}
                {showRichTextEditor && <RichTextEditor response={response} setResponse={setResponse} />}
            </div>
        </div>
    )
}

export default GeneratedResource;
