import React from 'react';

const CookiesBanner = ({ setShowCookiesBanner }) => {

    const handleClose = () => {
        setShowCookiesBanner(false);
    }


    if (document.cookie.indexOf("cookieUse") !== -1) {
        handleClose();
    }

    const setBanner = () => {
        // set the cookie with a expiration date of one week from now
        let date = new Date();
        date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));
        document.cookie = "cookieUse=agreed; expires=" + date.toGMTString();
        handleClose();
    }

    return (
        <div className="w-screen h-16 bg-indigo-700 opacity-90 flex justify-around items-center fixed bottom-0">
            <div className="text-[10px] px-2 md:px-0 md:text-sm text-white">
                <p>This site use cookies to enhance experience. By using this site you agree to all site policies.</p>
                <p>Buying through affiliate links may earn us commission, which is used to improve services.</p>
            </div>
            <button
                onClick={setBanner}
                className="justify-center py-2 px-4 mx-4 w-[80px] md:w-[160px] border border-transparent shadow-sm text-sm font-medium rounded-md text-indigo-600 hover:text-white bg-indigo-100 hover:bg-indigo-400">
                Accept
            </button>
        </div>
    )
}

export default CookiesBanner;
