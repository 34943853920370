import React from 'react';

function PrintButton(props) {
    const print = () => {
        let newWindow = window.open();
        let printableArea = newWindow.document.createElement("div");

        // Inject content with inline styles for font-family
        printableArea.innerHTML = `
            <pre style="font-family: Times New Roman, serif; margin-top: 20px; white-space: pre-wrap;">
                ${props.content}
            </pre>
        `;

        newWindow.document.body.appendChild(printableArea);

        // Print and clean up
        newWindow.print();
        newWindow.document.body.removeChild(printableArea);
        newWindow.close();
    }
    return (
        <button onClick={print} className="h-8 w-24 rounded bg-indigo-600 hover:bg-indigo-300 text-white font-ivyora-display">Print</button>
    );
}

export default PrintButton;
