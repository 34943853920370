import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { csrfFetch } from "../../store/csrf";
import { ReactComponent as Lightning } from "./lightning-bolt.svg"
import IsdNav from "./IsdNav";

const Resource = () => {
    const [type, setType] = useState('');
    const [id, setId] = useState('');
    const location = useLocation();
    const [resourceText, setResourceText] = useState();
    const [showSearch, setShowSearch] = useState(false);
    const [showMyAccount, setShowMyAccount] = useState(false);
    const [showNotificationContainer, setShowNotificationContainer] = useState(false);

    useEffect(() => {
        const fetchResource = async() => {
            const params = queryString.parse(location.search);
            const typeParam = params.type;
            const idParam = params.id;

            // Update state variables with the query parameter values
            if (typeParam) setType(typeParam);
            if (idParam) setId(idParam);

            const response = await csrfFetch('/api/teks/fetch-resource', {
                method: "POST",
                body: JSON.stringify({
                    type: typeParam,
                    id: idParam,
                }),
            });
            const data = await response.json();
            console.log(data)
            setResourceText(data.resource.content)
            return data;
        }

        if (!type && !id) {
            fetchResource();
        }
    }, [location.search])

    return (
        <div className="md:grid md:grid-cols-12 gap-4">
            <IsdNav setShowSearch={setShowSearch} setShowMyAccount={setShowMyAccount}/>
            {!showNotificationContainer &&
                <div className="col-start-1 md:col-start-2 pl-4 md:pl-0 col-span-12 md:col-span-8 mt-4">
                    <p className="font-ivyora-display text-2xl tracking-wide text-indigo-600">
                        AI Worksheet Builder For Teachers
                    </p>
                    <div className="flex">
                        <p className="pl-2 font-ivyora-display mt-2 text-sm tracking-wide">Generate lesson plans,
                            worksheets, and tests instantly</p>
                        <Lightning className="pt-2"/>
                    </div>
                </div>}
            <div className="w-auto md:col-start-3 px-6 md:col-span-8 whitespace-pre-wrap pt-16 mb-12 font-owners-text font-bold">
                    {resourceText}
                </div>
        </div>
    )
}

export default Resource;
