import React, { useState, useEffect } from 'react';
import IsdNav from "./IsdNav";
import ResourceForm from "./ResourceForm";
import GeneratedResource from "./GeneratedResource";
import {useDispatch, useSelector} from "react-redux";
import * as sessionActions from "../../store/session";
import Footer from "../Footer";
import Search from "./Search";
import MyAccount from "./MyAccount";
import {csrfFetch} from "../../store/csrf";
import {useCookies} from "react-cookie";
import CookiesBanner from "./CookiesBanner";
import { ReactComponent as Lightning } from "./lightning-bolt.svg"
import {Helmet} from "react-helmet";

const ISD = () => {
    const dispatch = useDispatch();
    const sessionUser = useSelector((state) => state.session.user);
    const [showGeneratedContent, setShowGeneratedContent] = useState(false);
    const [subject, setSubject] = useState(null);
    const [language, setLanguage] = useState('english');
    const [testQuestionTypes, setTestQuestionTypes] = useState(null);
    const [numberOfQuestions, setNumberOfQuestions] = useState(null);
    const [worksheetTypes, setWorksheetTypes] = useState([]);
    const [questionTypes, setQuestionTypes] = useState([]);
    const [selectedChemicals, setSelectedChemicals] = useState(null);
    const [selectedEquipment, setSelectedEquipment] = useState(null);
    const [grade, setGrade] = useState(null);
    const [type, setType] = useState("freestyle-lessonplan"); //sets the initial resource type
    const [userId, setUserId] = useState(null);
    const [selectedTek, setSelectedTek] = useState(null);
    const [showSearch, setShowSearch] = useState(false);
    const [showMyAccount, setShowMyAccount] = useState(false);
    const [showNotificationContainer, setShowNotificationContainer] = useState(false);
    const [notificationContainer, setNotificationContainer] = useState(null);
    //adv variables, could be updated later
    const [advGroupId, setAdvGroupId] = useState(1);
    const [advsArr, setAdvsArr] = useState([]);
    const [advsBannerArr, setAdvsBannerArr] = useState([]);
    const [showCookiesBanner, setShowCookiesBanner] = useState(true);

    useEffect(() => {
        async function setUserAndSubscription() {
            if (sessionUser) {
                setUserId(sessionUser.id);
                dispatch(sessionActions.getSubscription(sessionUser.id));
            } else {
                setUserId(1);
            }
        }

        setUserAndSubscription();
    }, [sessionUser])

    useEffect(() => {
        if (advsArr.length === 0) {
            async function getAdvs() {
                const ta_adv_group_id = advGroupId;
                const response = await csrfFetch('/api/advs/get-advs-by-group', {
                    method: 'POST',
                    body: JSON.stringify({
                        ta_adv_group_id
                    }),
                });
                const data = await response.json();
                const randomizedAddArr = [];
                data.advs.forEach(adv => {
                    if (randomizedAddArr.length === 0) {
                        randomizedAddArr.push(adv);
                    } else {
                        let randomIndex = Math.floor(Math.random() * randomizedAddArr.length - 1);
                        randomizedAddArr.splice(randomIndex, 0, adv)
                    }

                })
                setAdvsArr(randomizedAddArr)

                //set the banner ads
                let bannerArr = [];
                let result = [];
                for (let i = 0; i < randomizedAddArr.length; i += 5) {
                    bannerArr.push(randomizedAddArr.slice(i, i + 5));
                }
                console.log(bannerArr)
                setAdvsBannerArr(bannerArr)
            }

            getAdvs();
        }
    }, [advsArr])

    return(
        <div class="grid grid-cols-12 gap-4">
            <Helmet>
                <title>Education Resource Generator</title>
                <meta name="description" content="Lightning fast worksheet generator for teachers. Generate educational resources in an instant." />
                <meta property="og:title" content="Education Resource Generator" />
                <meta property="og:description" content="Lightning fast worksheet generator for teachers. Generate educational resources in an instant." />
                <meta property="og:image" content="https://user-images.githubusercontent.com/74638539/217420111-f38ec093-4b7d-4a82-86d2-0a7ead8a7f7a.jpg" />
                <meta property="og:url" content="https://www.teachersaide.io"/>
                <meta property="og:type" content="website" />
                <meta property="twitter:card" content="https://user-images.githubusercontent.com/74638539/217420111-f38ec093-4b7d-4a82-86d2-0a7ead8a7f7a.jpg" />
                <meta property="twitter:title" content="Education Resource Generator" />
                <meta property="twitter:description" content="Lightning fast worksheet generator for teachers. Generate educational resources in an instant." />
                <meta property="twitter:image" content="https://user-images.githubusercontent.com/74638539/217420111-f38ec093-4b7d-4a82-86d2-0a7ead8a7f7a.jpg" />
                <link rel="canonical" href="https://www.teachersaide.io" />
            </Helmet>
            <IsdNav setShowSearch={setShowSearch} setShowMyAccount={setShowMyAccount} />
            {!showNotificationContainer &&
                <div className="col-start-1 md:col-start-2 pl-4 md:pl-0 col-span-12 md:col-span-8 mt-4">
                    <p className="font-ivyora-display text-2xl tracking-wide text-indigo-600">
                    AI Worksheet Builder For Teachers
                    </p>
                    <div className="flex">
                        <p className="pl-2 font-ivyora-display mt-2 text-sm tracking-wide">Generate lesson plans, worksheets, and tests instantly</p>
                        <Lightning className="pt-2" />
                    </div>
                </div>
            }            {!showNotificationContainer && !showMyAccount && !showSearch && !showGeneratedContent && <ResourceForm setShowGeneratedContent={setShowGeneratedContent}
                                                    setSubject={setSubject}
                                                    setLanguage={setLanguage}
                                                    setTestQuestionTypes={setTestQuestionTypes}
                                                    setNumberOfQuestions={setNumberOfQuestions}
                                                    setWorksheetTypes={setWorksheetTypes}
                                                    worksheetTypes={worksheetTypes}
                                                    setQuestionTypes={setQuestionTypes}
                                                    setSelectedChemicals={setSelectedChemicals}
                                                    setSelectedEquipment={setSelectedEquipment}
                                                    setGrade={setGrade}
                                                    setType={setType}
                                                    setSelectedTek={setSelectedTek}
                                                                                     userId={userId}
                                                                                     setShowNotificationContainer={setShowNotificationContainer}
                                                                                     setNotificationContainer={setNotificationContainer}
                                                                                                                                advsArr={advsArr}
                                                                                                                                advsBannerArr={advsBannerArr}
            />}
            {!showNotificationContainer && !showMyAccount && !showSearch && showGeneratedContent && <GeneratedResource setShowGeneratedContent={setShowGeneratedContent}
                                                        showGeneratedContent={showGeneratedContent}
                                                        subject={subject}
                                                        language={language}
                                                        questionTypes={questionTypes}
                                                        numberOfQuestions={numberOfQuestions}
                                                        worksheetTypes={worksheetTypes}
                                                        selectedChemicals={selectedChemicals}
                                                        selectedEquipment={selectedEquipment}
                                                        grade={grade}
                                                        type={type}
                                                        userId={userId}
                                                        selectedTek={selectedTek}
            />}

            {!showNotificationContainer && !showMyAccount && showSearch && <Search />}
            {!showNotificationContainer && showMyAccount && <MyAccount />}
            {showNotificationContainer && notificationContainer}
            {showCookiesBanner && <CookiesBanner setShowCookiesBanner={setShowCookiesBanner} />}
            {!showGeneratedContent && <Footer />}
        </div>
    )
}

export default ISD;
