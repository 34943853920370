import { csrfFetch } from '../store/csrf.js';

function getDeviceType() {
    const ua = navigator.userAgent;
    if (/mobile/i.test(ua)) return 'Mobile';
    if (/tablet/i.test(ua)) return 'Tablet';
    return 'Desktop';
}

function getBrowser() {
    const ua = navigator.userAgent;
    if (ua.includes('Chrome')) return 'Chrome';
    if (ua.includes('Firefox')) return 'Firefox';
    if (ua.includes('Safari')) return 'Safari';
    if (ua.includes('Edge')) return 'Edge';
    if (ua.includes('MSIE') || ua.includes('Trident')) return 'Internet Explorer';
    return 'Other';
}

function getScreenResolution() {
    return `${window.screen.width}x${window.screen.height}`;
}

function getOS() {
    const ua = navigator.userAgent;
    if (ua.includes('Win')) return 'Windows';
    if (ua.includes('Mac')) return 'MacOS';
    if (ua.includes('X11')) return 'UNIX';
    if (ua.includes('Linux')) return 'Linux';
    if (ua.includes('Android')) return 'Android';
    if (ua.includes('like Mac')) return 'iOS'; // iOS user agents contain 'like Mac' rather than iOS
    return 'Other';
}

function getDeviceInfo() {
    return {
        deviceType: getDeviceType(),
        browser: getBrowser(),
        os: getOS(),
        screenResolution: getScreenResolution()
    };
}

export async function addUserinteraction(user_id, interaction_type, interaction_details, page_url) {
    const device_info = JSON.stringify(getDeviceInfo());

    const response = await csrfFetch('/api/userinteraction/create-userinteraction', {
        method: "POST",
        body: JSON.stringify({
            user_id, 
            interaction_type, 
            interaction_details, 
            page_url, 
            device_info, 
        }),
      });
      
    const data = await response.json();

    return data;
}

