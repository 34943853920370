import React, {useState} from 'react';
import EmailTextEditor from "../EmailTextEditor";
import {csrfFetch} from "../../../../store/csrf";

const EditEmailTemplate = ({setShowModal= {setShowModal}, setModalType= {setModalType}, ta_email_template_id = {ta_email_template_id}, templateName={templateName}, templateSubject={templateSubject}, emailContent={emailContent}, emailText={emailText} }) => {
    const [tempName, setTemplateName] = useState(templateName);
    const [subject, setSubject] = useState(templateSubject);
    const [html, setHTML] = useState(emailContent);
    const [text, setText] = useState(emailText);
    const [updateMessage, setUpdateMessage] = useState(null)

    const closeAddTemplateModal = () => {
        setShowModal(false);
    }

    const updateEmailTemplate = async(e) => {
        try {
            e.preventDefault();
            const name = tempName;
            const content = html;
            const email_text = text;
            const response = await csrfFetch('/api/emails/update-email-template', {
                method: 'POST',
                body: JSON.stringify({
                    ta_email_template_id, name, subject, content, email_text
                })
            });

            if (response) {
                setUpdateMessage("Successfully Updated")
                setTimeout(() => {
                    setShowModal(false);
                }, 1200)
            }
        } catch(error) {
            setUpdateMessage("Not able to update template.")
        }
    }
    return (
        <div className="w-[800px]">
            <form>
                <label>
                    <input
                        type="text"
                        value={tempName}
                        onChange={(e) => setTemplateName(e.target.value)}
                        required
                        className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                        placeholder="Template Name"
                    />
                </label>
                <label>
                    <input
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                        className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                        placeholder="Email Subject"
                    />
                </label>
            </form>
            <EmailTextEditor html={html} setHTML={setHTML} text={text} setText={setText} />
            <button
                className="bg-zinc-300 group relative font-owners-text tracking-wide flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                onClick={() => closeAddTemplateModal()}
            >
                Cancel
            </button>
            <div className="w-44"></div>
            <div className="flex mt-8">
                <button
                    onClick={e => updateEmailTemplate(e)}
                    className="bg-[#1bcae1] group relative font-owners-text tracking-wide flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                >
                    Update Template
                </button>
            </div>
            {updateMessage}
        </div>
    )
}

export default EditEmailTemplate;
