import React, {useState, useEffect} from 'react';
import {csrfFetch} from "../../../store/csrf";
import ResourceContainer from "./ResourceContainer";

const Search = () => {
    const [subject, setSubject] = useState("");
    const [grade, setGrade] = useState("");
    const [type, setType] = useState('lessonplan');
    const [searchResultsArr, setSearchResultsArr] = useState([]);
    const [showResource, setShowResource] = useState(false);
    const [selectedResource, setSelectedResource] = useState(null);
    const [recentResources, setRecentResources] = useState(null);

    useEffect(() => {
        console.log("Hello")
        const fetchRecectResources = async() => {
            const response = await csrfFetch('/api/teks/fetch-recent-resources', {
                method: 'GET'
            })
            const data = await response.json();
            setRecentResources(data.resources)
            console.log(data.resources)
        };

        if (!recentResources) {
            fetchRecectResources();
        }
    }, [recentResources]);

    const updateType = (e) => {
        e.preventDefault();
        const newType = e.target.value;
        setType(newType);
    }

    const searchResources = async(e) => {
        e.preventDefault();

        const response = await csrfFetch('/api/search/search-resources', {
            method: 'POST',
            body: JSON.stringify({
                subject,
                grade,
                type
            }),
        });
        const data = await response.json();

        //Consolidate data from different tables
        let searchArr = [];
        data.dataEntries.forEach(result => {
            if (result.length > 0) {
                result.forEach(entry => {
                    searchArr.push(entry);
                })
            }
        })

        setSearchResultsArr([...searchArr])
    }

    const showResult = (e, resource) => {
        e.preventDefault();
        setSelectedResource(resource);
        setShowResource(true)
    }

    return (
        <div className="max:w-screen ml-4 md:ml-0 md:col-start-4 md:pl-12 col-span-6">
            {!showResource && <div className="w-full">
                <p className="text-sm mt-4">Search for pre-made resources.</p>
                <form className="w-full mb-6 mt-6" onSubmit={e => searchResources(e)}>
                    <div className="flex mb-6">
                        <label className="px-2">
                            Subject
                            <input
                                className="h-10 border border-grey-100 px-4 w-[260px] ml-2"
                                type="text"
                                onChange={e => setSubject(e.target.value)}
                                placeholder="Guided reading, atomic theory, etc..."
                                required={true}
                            />
                        </label>
                        <label className="px-2">
                            Grade
                            <input
                                className="h-10 border border-grey-100 px-4 w-[50px] ml-2"
                                type="text"
                                value={grade}
                                onChange={e => setGrade(e.target.value)}
                                placeholder="6"
                                required={true}
                            />
                        </label>
                    </div>
                    <label className="px-2">
                        Type
                        <select onChange={e => updateType(e)}
                                className="h-10 border border-grey-100 px-4 w-[180px] ml-2">
                            <option>Lesson Plan</option>
                            <option>Worksheet</option>
                            <option>Test</option>
                        </select>
                    </label>
                    <button className="mt-4 md:mt-0 ml-10 md:ml-8 bg-indigo-600 h-10 w-[300px] md:w-[180px] text-white rounded-md">Search</button>
                </form>
                <div className="mt-12 md:mt-24 w-full text-wrap">
                    <p className="my-4">Recently Created:</p>
                    <table className="text-left">
                        <tr>
                            <th>Subject</th>
                            <th className="px-2 md:px-12">Grade</th>
                            <th>Resource Body</th>
                        </tr>
                        {recentResources?.map((resource, index) => (
                            <tr onClick={e => showResult(e, resource)} className="hover:text-indigo-600 cursor-pointer ">
                                <td>{resource.subject}</td>
                                <td className="md:px-12">{resource.grade}</td>
                                <td>{resource.content.slice(0, 100)}...</td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>}

            {!showResource && searchResultsArr.length > 0 &&
                <div className="mt-8">
                    <table className="text-left">
                        <tr>
                            <th>Subject</th>
                            <th className="px-12">Grade</th>
                            <th>Resource Body</th>
                        </tr>
                    </table>
                    {searchResultsArr.map((resource, index) => (
                        <tr onClick={e => showResult(e, resource)} className="hover:text-indigo-600 cursor-pointer">
                            <td>{resource.subject}</td>
                            <td className="px-12">{resource.grade}</td>
                            <td>{resource.content.slice(0, 100)}</td>
                        </tr>
                    ))}
                </div>
            }

            {showResource &&
                <div>
                    <button onClick={() => setShowResource(false)} className="bg-indigo-600 mb-12 h-10 w-[80px] text-white rounded-md">Back</button>
                    <ResourceContainer selectedResource={selectedResource} />
                </div>
                }
        </div>
    )
}

export default Search;
