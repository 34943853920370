import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import * as sessionActions from "../../../store/session";

const IsdNav = ({ setShowSearch, setShowMyAccount }) => {
    const dispatch = useDispatch();
    const sessionUser = useSelector((state) => state.session.user);
    const [user, setUser] = useState(null);
    const [searchNavButtonBg, setSearchNavButtonBg] = useState('white');
    const [generatorNavButtonBg, setGeneratorNavButtonBg] = useState('indigo-600')

    useEffect(() => {
        if (sessionUser) {
            setUser(sessionUser)
        }
    });

    const logout = (e) => {
        e.preventDefault();
        dispatch(sessionActions.logout());
    };

    const searchNavClicked = () => {
        setSearchNavButtonBg('indigo-600');
        setGeneratorNavButtonBg('white');
        window.location.href = '/';
    }

    const generatorNavClicked = () => {
        setSearchNavButtonBg('white');
        setGeneratorNavButtonBg('indigo-600');
        window.location.href = '/';
    }

    const accountNavClicked = () => {
        setShowSearch(false);
        setShowMyAccount(true);
        setSearchNavButtonBg('white');
        setGeneratorNavButtonBg('indigo-600');
    }

    return (
        <div className="grid grid-cols-6 col-start-1 col-end-13 bg-[#f4f4f4] h-24 md:h-32 grid-rows-3 text-indigo-800">
            <div className="flex row-start-2 col-start-1 col-end-3 row-span-1 ml-2 md:ml-16 pt-3">
                <button onClick={() => generatorNavClicked()} className={`bg-${generatorNavButtonBg} text-${searchNavButtonBg} rounded-2xl h-4 w-24 text-xs`}>Create</button>
                <button onClick={() => searchNavClicked()} className={`bg-${searchNavButtonBg} text-${generatorNavButtonBg} rounded-2xl mx-4 md:mx-8 h-4 w-24 mr-4 text-xs`}>Search</button>
            </div>
            <div className="flex row-start-2 col-start-3 col-end-5 w-[100%] justify-center md:pt-3">
                <p className="font-ivyora-display italic font-bold text-2xl">TeachersAIde</p>
            </div>

            <div className="flex row-start-2 justify-center md:pt-3 col-start-6 text-indigo-600">
                {sessionUser ? <a onClick={accountNavClicked} className="text-[14px] md:text-md font-owners-text tracking-wide block md:pt-2 md:px-4 cursor-pointer">My Account</a> :  <NavLink exact to="/login" className="text-[14px] md:text-md font-owners-text tracking-wide block md:pt-2 md:px-4">Log In</NavLink>}
                {sessionUser ? <a onClick={logout} className="text-[14px] md:text-md font-owners-text tracking-wide block md:pt-2 md:px-4 cursor-pointer">Log Out</a> : <NavLink exact to="/signup" className="text-[14px] md:text-md font-owners-text tracking-wide block md:pt-2 md:px-4">Sign Up</NavLink>}
            </div>
        </div>
    )
}

export default IsdNav;
